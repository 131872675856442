
.custom-overlay {
    background-color: rgba(0, 0, 0, 0.75);
}

.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 300px;
    background-size: cover;
    background-position: center;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 10px;
}
.custom-modal-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 350px;
    background-image: url('../assets/img/promotion.jpg');
    background-size: cover;
    background-position: center;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 10px;
}
@media (max-width: 768px) {
    .custom-modal-2 {
        width: 100%;
        height: 70%;
    }
    .close-button {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 20px;
        padding: 10px;
        background: yellow;
        color: black;
        font-size: 20px;
        cursor: pointer;
    }
}

@media (max-width: 480px) {
    .custom-modal-2 {
        width: 70%;
        height: 65%;
    }
    .close-button{
        position: relative;
        top: 0;
        right: 0;
        background: yellow;
        color: black;
        font-size: 20px;
        cursor: pointer;
        margin-left: -20px;
    }
}
.close-button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 20px;
    padding: 10px;
    background: yellow;
    color: black;
    font-size: 20px;
    cursor: pointer;
}
